.AppHeader {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  /* align-content: center; */
  display: flex;
  flex-direction: column;
  width: 100%;
}
